import { useState } from "react"
import styled from "styled-components"

import { subDays } from "date-fns"

import {
  DeviceGraphs,
  IGraphDateRange,
} from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { ExportDeviceDataDialog } from "src/components/Homes/ExportDeviceDataDialog/ExportDeviceDataDialog"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { THome } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DeviceDetailsHistory({
  device,
  home,
}: {
  device: TDevice
  home: THome
}) {
  const { t, langKeys } = useTranslate()

  const [exportDialogOpen, setExportDialogOpen] = useState(false)

  const fetchProfile = useFetchProfile({
    id: home?.profile_id || "",
    options: { enabled: !!home.profile_id },
  })

  const [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 1),
    endDate: new Date(),
  })

  function handleDateRangeChange({ startDate, endDate }: IGraphDateRange) {
    setDateRange({ startDate, endDate })
  }

  const instantlyTurnOnAlarm =
    fetchProfile.data?.security_alarm?.instantly_turn_on ?? null

  return (
    <Box>
      <DataHistoryTop>
        <MText variant="subtitle">
          {t(langKeys.placeholder_web, { text: "Data history" })}
        </MText>

        <ActionsBox>
          <MButton variant="minimal" onClick={() => setExportDialogOpen(true)}>
            {t(langKeys.export)}
          </MButton>

          <DateRangePickerBox>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDateRangeChange={handleDateRangeChange}
              dateRangeProps={{
                minBookingDays: 2,
                numberOfMonths: 1,
              }}
              defaultPresetKey="DAY"
            />
          </DateRangePickerBox>
        </ActionsBox>
      </DataHistoryTop>

      <DeviceGraphs
        device={device}
        home={home}
        dateRange={dateRange}
        hideGraphBorder
        instantlyTurnOnAlarm={instantlyTurnOnAlarm}
      />

      <ExportDeviceDataDialog
        device={device}
        home={home}
        open={exportDialogOpen}
        dateRange={dateRange}
        onClose={() => setExportDialogOpen(false)}
      />
    </Box>
  )
}

const Box = styled.div`
  position: relative;
`

const DataHistoryTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.M} ${spacing.XL};
`

const ActionsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.XS2};
`

const DateRangePickerBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
  padding: ${spacing.XS} ${spacing.M};
`
