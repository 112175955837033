import { useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import { OccupancyButton } from "src/components/Homes/HomeDetails/Occupancy/OccupancyButton"
import { OccupancyContent } from "src/components/Homes/HomeDetails/Occupancy/OccupancyContent"
import { OccupancyIcon } from "src/components/Homes/HomeDetails/Occupancy/OccupancyIcon"
import { CalibrationEmptyState } from "src/components/Homes/HomeDetailsV2/MonitoringCards/CalibrationEmptyState"
import { MonitoringCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/MonitoringCardV2"
import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"
import {
  DEFAULT_OCCUPANCY_THRESHOLD,
  THome,
} from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { CheckCircleIcon } from "src/ui/CheckCircleIcon/CheckCircleIcon"
import { semanticEmergency } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import CalibratingIcon from "src/ui/icons/calibrating.svg"
import GraphPlotIcon from "src/ui/icons/graph-plot.svg"
import { MInfo } from "src/ui/Info/MInfo"
import { localizedDate } from "src/ui/LocalizedDate/localized-date"
import { spacing } from "src/ui/spacing"
import { extractHHMM } from "src/utils/l10n"

export function OccupancyCardV2({ home }: { home: THome }) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const clockType = useGetUser().clock_type

  const occupancy = home.occupancy
  const isActive = !!occupancy?.active
  const detected = occupancy?.latest_scanned_devices || 0
  const threshold = occupancy?.scanned_devices_threshold || 0
  const isAboveThreshold = occupancy?.status === "crowd_detected"
  const progress = (detected / threshold) * 100

  const canToggleOccupancy = getAccessLogic({
    role: org.user_role,
  }).hasAdminAccess

  const latestScan = occupancy?.latest_scanned_devices_updated_at

  const time = latestScan
    ? extractHHMM(
        new Date(localizedDate(latestScan, clockType, home.timezone)),
        clockType
      )
    : ""

  function ActionButton() {
    return (
      <OccupancyButton
        occupancy={occupancy}
        violations={home.violations}
        homeId={home.home_id}
        toggleAllowed={canToggleOccupancy}
      />
    )
  }

  function OccupancyActive() {
    if (isActive && occupancy.status !== "waiting_for_data") {
      if (isAboveThreshold) {
        return (
          <CrowdRiskBox>
            {t(langKeys.crowd_detect_active_emergency_title, { 0: time })}

            <MInfo content={t(langKeys.crowd_detect_crowd_risk_description)} />
          </CrowdRiskBox>
        )
      }

      return (
        <StatusBox>
          <StyledIconBox>
            <CheckCircleIcon svgProps={{ width: 20 }} />
            {t(langKeys.occupancy_scanned_device_limit_subceeded_title)}
          </StyledIconBox>

          <LinearProgressBar segments={[{ value: progress }]} />
        </StatusBox>
      )
    }

    return null
  }

  const [showGraphDetail, setShowGraphDetail] = useState(false)

  return (
    <MonitoringCardV2
      icon={
        <OccupancyIcon
          active={isActive}
          status={occupancy?.status}
          iconProps={{ height: "40" }}
        />
      }
      title={t(langKeys.crowd_detect_title)}
      status={<OccupancyActive />}
      action={
        <OccupancyActions>
          <div>
            <IconButton onClick={() => setShowGraphDetail(true)} size={"small"}>
              <GraphPlotIcon width={24} />
            </IconButton>
          </div>
          <ActionButton />
        </OccupancyActions>
      }
    >
      {occupancy?.status === "waiting_for_data" && (
        <CalibrationEmptyState
          icon={<CalibratingIcon width={40} />}
          body={t(langKeys.crowd_detect_analyzing_title)}
          description={t(langKeys.crowd_detect_analyzing_short_description)}
        />
      )}

      <GraphDetailOccupancy
        home={home}
        open={showGraphDetail}
        onClose={() => setShowGraphDetail(false)}
      />
    </MonitoringCardV2>
  )
}

const OccupancyActions = styled.div`
  display: flex;
  gap: ${spacing.M};
`

const StatusBox = styled.div`
  display: grid;
  row-gap: ${spacing.XS};
`

const StyledIconBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`

const CrowdRiskBox = styled.div`
  display: flex;
  color: ${semanticEmergency};
`

function GraphDetailOccupancy({
  open,
  onClose,
  home,
}: {
  open: boolean
  onClose: () => void
  home: THome
}) {
  const { t, langKeys } = useTranslate()
  const occupancy = home.occupancy
  const {
    status,
    latest_scanned_devices: detected,
    scanned_devices_threshold: threshold,
    latest_scanned_devices_updated_at: lastUpdate,
  } = occupancy || {}
  const aboveThreshold = status === "crowd_detected"

  return (
    <MDialog
      open={open}
      onClose={onClose}
      width="full"
      title={t(langKeys.crowd_detect_title)}
    >
      <OccupancyContent
        status={status}
        threshold={threshold || DEFAULT_OCCUPANCY_THRESHOLD}
        detected={detected}
        aboveThreshold={aboveThreshold}
        lastUpdate={lastUpdate}
        homeId={home.home_id}
        timezone={home?.timezone}
        graphCSSProps={{ height: "60vh" }}
      />
    </MDialog>
  )
}
